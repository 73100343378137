import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "toggle"]

  connect() {
    this.visible = false
  }

  toggle(event) {
    event.preventDefault()
    if (!this.visible) {
      this.inputTarget.type = "text"
      this.toggleTarget.classList.add("fa-eye-slash")
      this.toggleTarget.classList.remove("fa-eye")
    } else {
      this.inputTarget.type = "password"

      this.toggleTarget.classList.add("fa-eye")
      this.toggleTarget.classList.remove("fa-eye-slash")
    }

    this.visible = !this.visible
  }
}
