import { Controller } from "@hotwired/stimulus"
import hotkeys from 'hotkeys-js'

export default class extends Controller {
    static values = {
        bindings: { type: Array, default: ["ctrl+k", "cmd+k"] }
    }

    connect() {
        const handler = (event, handler) => this.handleEvent()
        for (let binding of this.bindingsValue) {
            hotkeys(binding, handler)
        }
    }

    handleEvent() {
        this.element.focus()
    }
}