import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["output"]
    static values = { url: String, filterName: String }

    abortController = null;

    connect() {
        this.element.dataset.action = "searchable-table:loadStart->spinner#start searchable-table:loadEnd->spinner#stop"
    }

    cancelPendingRequest() {
        if (this.abortController) {
            this.abortController.abort()
        }

        this.abortController = new AbortController()
    }

    filter(event) {
        this.cancelPendingRequest()

        const url = new URL(this.urlValue)
        url.searchParams.set(`${this.filterNameValue}[q]`, event.target.value)

        const el1 = this.outputTarget

        const onLoadEndCallback = this.onLoadEnd.bind(this)

        this.onLoadStart()

        fetch(url, { signal: this.abortController.signal }).then((response) => response.text()).then(function (data) {
            el1.innerHTML = data
        }).then(onLoadEndCallback)
    }

    onLoadStart() {
       this.dispatch("loadStart");
    }

    onLoadEnd(_) {
        this.dispatch("loadEnd");
    }
}