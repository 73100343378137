import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="spinner"
export default class extends Controller {
  static targets = ["toggle"]
  start() {
    this.toggleTarget.classList.remove("d-none")
  }
  stop() {
    this.toggleTarget.classList.add("d-none")
  }
}
