import { Controller } from "@hotwired/stimulus"
import { validateIBAN } from 'ibantools/build/ibantools.js'

// Connects to data-controller="iban-validator"
export default class extends Controller {
  static targets = ["input", "error", "status"]

  setErrorMessage(text) {
    this.errorTarget.innerText = text
  }

  validate(event) {
    event.preventDefault()

    const r = validateIBAN(this.inputTarget.value)

    if (r.valid) {

    } else {
      this.setErrorMessage(r.error)
    }
  }
}
