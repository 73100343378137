import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="multi-downloader"
export default class extends Controller {
  static targets = ["link"]
  
  download(event) {
    event.preventDefault()

    this.linkTargets.forEach(element => {
      window.open(element.href, element.id)
    });
  }
}
