import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
  static targets = ["spinner"]
  show() {
    this.spinnerTarget.classList.remove("d-none")
  }
  hide() {
    this.spinnerTarget.classList.add("d-none")
  }
}
