import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="stancer--sepa"
export default class extends Controller {
  static values = { publickey: String, url: { type: String, default: 'https://api.stancer.com/v1/sepa/' } }
  static targets = ["iban", "name", "mandateId", "lastFourDigits", "sepaId"]

  submit(event) {
    event.preventDefault()

    const body = JSON.stringify({
      iban: this.ibanTarget.value,
      name: this.nameTarget.value
    })

    const h = {
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa(this.publickeyValue + ':')
    }

    fetch(this.urlValue, {
      method: 'POST',
      headers: h,
      body: body
    }).then((resp) => resp.json()).then((json) => {
      if (json.id) { 
        this.lastFourDigitsTarget.value = json.last4
        this.mandateIdTarget.value = json.mandate
        this.sepaIdTarget.value = json.id

        this.element.submit()
      }
    });
  }
}
